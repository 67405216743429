import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { getApplicableForm, isLatestForm } from "../../project/ProjectHelper";
import GdButton from "../../utils/GdButton";
import { DataFieldInput, Operation, OperationInput, Sector } from "../generated/graphql";
import { formIsPreview } from "./FormHelper";
import { Form, formatValue, getAttachments, getDisplayableFields } from "./formsTypes";

interface OperationsViewerProps {
  sector: Sector;
  zipCode: string;
  operations: OperationInput[] | Operation[];
  editOperation?: (o: OperationInput) => void;
  duplicateOperation?: (o: OperationInput) => void;
  removeOperation?: (o: OperationInput) => void;
  updateOperationCpe?: (o: OperationInput) => void;
  addCpeCheckbox?: boolean;
  isReadOnly?: boolean;
  dontTouchOperations: boolean;
}

export enum JustificationType {
  zni = "zni",
  cdp = "cdp",
  other = "other",
}

const OperationsViewer: FC<OperationsViewerProps> = ({
  sector,
  zipCode,
  operations,
  editOperation,
  duplicateOperation,
  removeOperation,
  addCpeCheckbox,
  updateOperationCpe,
  isReadOnly,
  dontTouchOperations,
}) => {
  const { t } = useTranslation("project");

  return (
    <>
      {operations.map((o) => {
        const form = getApplicableForm(o, sector, zipCode);

        if (!form) return undefined;
        const fields = getDisplayableFields(form?.fields || [], o.data);
        const { computation } = o as Operation;
        const attachments = getAttachments(form as Form, o.data as DataFieldInput[]);
        const isStillValid = dontTouchOperations || isLatestForm(o, sector, zipCode);

        return (
          <Accordion key={`${o.id}${o.formId}`}>
            <AccordionSummary expandIcon={<ExpandMore />} sx={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {o.machineName ? (
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "20px",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}>
                    ({o.machineName})
                  </Typography>
                ) : (
                  ""
                )}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography sx={{ display: "flex", alignItems: "center", paddingRight: "16px" }}>
                    {o.id} - <b style={{ margin: "0 4px" }}>{o.formId}</b>
                    {!editOperation && computation
                      ? ` : ${formatValue(computation.capacity, true, 3, false)} MWhca${
                          computation.valuation === "0"
                            ? ""
                            : ` / ${formatValue(computation.valuation, false, 2, true)} €`
                        }${attachments.length === 0 ? "" : ` / ${t("attachments", { count: attachments.length })}`}`
                      : undefined}
                    {computation?.cpeBonus &&
                      computation.cpeBonus !== "undefined" &&
                      computation.cpeBonus !== "null" &&
                      t("cpeBonus", { count: parseInt(computation.cpeBonus, 10) })}
                    {o.bonusDetails &&
                      o.bonusDetails?.length > 0 &&
                      `/ ${o.bonusDetails?.length} ${t("bonificationPrefix")}`}
                  </Typography>
                  {addCpeCheckbox && (sector === Sector.Bat || sector === Sector.Bar) ? (
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={o.isCpeScoped || false}
                        label={o.isCpeScoped ? t("operationWithCpe") : t("operationWithoutCpe")}
                        onClick={(e) => e.stopPropagation()}
                        onFocus={(e) => e.stopPropagation()}
                        onChange={(_, newChecked) => {
                          const tempOperation = { ...o };
                          tempOperation.isCpeScoped = newChecked;
                          updateOperationCpe?.(tempOperation);
                        }}
                        disabled={isReadOnly}
                      />
                    </FormGroup>
                  ) : undefined}
                  {formIsPreview(form) ? (
                    <Typography sx={{ color: "red" }}>{t("operationIsPreview")}</Typography>
                  ) : !isStillValid ? (
                    <Typography sx={{ color: "red" }}>{t("invalidComputation")}</Typography>
                  ) : undefined}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row space-between margin-bottom">
                <Typography variant="h6">{form?.name}</Typography>
                <GdButton
                  label={t("seeForm")}
                  onClick={() => window.open(form?.formUrl)}
                  className="margin-left"
                  nowrap
                />
              </div>
              {fields.map((ff) => (
                <div key={ff.id} className="operation-fields">
                  <Typography variant="body2">
                    {ff.titles
                      ? ff.titles.find((ft) => ft.fieldValue === o.data?.find((d) => d?.fieldId === ft.fieldId)?.value)
                          ?.title
                      : ff.title}
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {ff.type === "number" || ff.type === "range"
                      ? formatValue(o.data.find((d) => d?.fieldId === ff.id)?.value, true, 3)
                      : ff.choices?.find((fc) => fc.value === o.data.find((d) => d?.fieldId === ff.id)?.value)?.name}
                  </Typography>
                </div>
              ))}
              {attachments.length === 0 ? undefined : (
                <>
                  <Typography variant="body2">{t("attachmentList", { count: attachments.length })}</Typography>
                  <ul>
                    {attachments.map((a) => (
                      <li key={a}>
                        <Typography variant="body2">{a}</Typography>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              <div className="big-margin-top">
                {o.bonusDetails && o.bonusDetails?.length > 0 ? (
                  <div className="margin-left">
                    <Typography style={{ textDecoration: "underline" }}>{t("bonus.list")}</Typography>
                    <ul style={{ marginTop: "0" }}>
                      {o.bonusDetails.map((b) => (
                        <li>
                          <div className="row" style={{ justifyContent: "space-between" }}>
                            <div className="row" style={{ gap: "8px" }}>
                              <Typography>
                                {b?.justification === JustificationType.cdp ||
                                b?.justification === JustificationType.zni
                                  ? t(`bonus.justification.${b?.justification}`)
                                  : b?.justification}
                                :
                              </Typography>
                              {b?.multiplicator && <Typography>x {b?.multiplicator}</Typography>}
                              {b?.addition && <Typography>+ {b?.addition} MWhca</Typography>}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <Typography>Aucun</Typography>
                )}
              </div>
              {editOperation ? (
                <div className="row space-evenly">
                  <GdButton label={t("removeOperation")} onClick={() => removeOperation?.(o)} color="inherit" />
                  <GdButton label={t("editOperation")} onClick={() => editOperation(o)} />
                  <GdButton label={t("duplicateOperation")} onClick={() => duplicateOperation?.(o)} color="secondary" />
                </div>
              ) : undefined}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

OperationsViewer.defaultProps = {
  editOperation: undefined,
  duplicateOperation: undefined,
  removeOperation: undefined,
  addCpeCheckbox: undefined,
  updateOperationCpe: undefined,
  isReadOnly: undefined,
};

export default OperationsViewer;
